import React from "react"
import { Link } from "gatsby"

import PageWrapper from "../components/PageWrapper"
import Section from "../components/Section"
import Container from "../components/Container"
import SectionTitle from "../components/SectionTitle"
import SectionText from "../components/SectionText"
import Button from "../components/Button"
import Input from "../components/forms/Input"

const SignUp = () => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasMiddleSocial: true,
          hasBottomSubscription: true,
          bottomClassName: "bg-primary-25 ",
          className: "!bg-primary-50",
        }}
      >
        <div>
          <Section isHero className="relative overflow-hidden">
            <Container>
              {/* section-wrap */}
              <div className="bg-primary-25 flex flex-col gap-14 shadow-lg tablet:px-28  px-5 py-8 tablet:py-14 rounded-3xl max-w-[612px] mx-auto">
                {/* top-part */}
                <div className="">
                  <SectionTitle className="text-center ">Sign up</SectionTitle>
                  <SectionText className="!pb-0 max-w-[300px] mx-auto text-center">
                    See your growth and get consulting support!
                  </SectionText>
                </div>

                {/* middle-part */}
                <div className="">
                  <div className="flex flex-col gap-8">
                    <form className="flex flex-col gap-6">
                      <Input
                        label="Full Name"
                        placeholder="John doe"
                        name="full-name"
                      />
                      <Input
                        type="email"
                        label="Email"
                        placeholder="johndoe@example.com"
                        name="email"
                      />
                      <Input
                        type="password"
                        label="Password"
                        placeholder="********"
                        name="password"
                      />
                      <Input
                        type="password"
                        label="Confirm Password"
                        placeholder="********"
                        name="confirm-password"
                      />
                      <Input
                        type="checkbox"
                        id="Checkbox4"
                        name="checkbox"
                        label={
                          <>
                            I agree to all{" "}
                            <Link to="#">
                              <span className="font-medium text-body-md text-primary-600">
                                privacy policyss
                              </span>{" "}
                            </Link>
                          </>
                        }
                      />
                      <Button type="submit" size={"xl"}>
                        Sign up
                      </Button>
                    </form>
                    <p className=" text-body-md text-neutral-500">
                      Have an account?{" "}
                      <Link to="/sign-in">
                        <span className="font-medium text-primary-600">
                          Login Now
                        </span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="absolute left-0 top-[20%] -z-20">
                <div
                  data-aos="fade-down-right"
                  className=" w-[258px]  bg-success-50 before:block before: content-[''] pb-[258px]"
                >
                  <div className="absolute w-[110px] bg-warning-50 before:block before:content-[''] pb-[110px] left-full top-full"></div>
                </div>
              </div>
              <div className="absolute bottom-0 right-0 translate-x-1/2 -z-10">
                <div
                  data-aos="fade-up-left"
                  className=" w-[400px] bg-neutral-50  before:block before:content-[''] pb-[400px] rounded-full"
                ></div>
              </div>
            </Container>
          </Section>
        </div>
      </PageWrapper>
    </>
  )
}

export default SignUp
